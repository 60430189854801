import React from "react"
import { Container } from "../components/Container"
import { Layout } from "../components/Layout"

export default ({}) => (
  <Layout
    seo={{
      title: "Neem contact op",
      path: "/contact",
      description: "Kijk samen naar de toekomst",
    }}
  >
    <section id="contact">
      <h3>Neem direct contact op</h3>
      <Container width="tight">
        <form
          method="POST"
          name="contact"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="contact" />
          <p className="hidden">
            <label>
              Don’t fill this out if you're human: <input name="bot-field" />
            </label>
          </p>
          <label htmlFor="name">Naam</label>
          <input id="name" name="name" />
          <label htmlFor="email">Email</label>
          <input id="email" name="email" type="email" />
          <label htmlFor="message">Bericht</label>
          <textarea id="message" name="message" rows={8} />
          <input type="submit" value="Verstuur" />
        </form>
      </Container>
    </section>
    <section id="map">
      <Container width="tight">
        <iframe
          width="100%"
          height="500"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=Detmarlaan%2035&t=&z=15&ie=UTF8&iwloc=&output=embed"
          frameBorder="0"
          scrolling="no"
        />
      </Container>
    </section>
  </Layout>
)
